<template>
  <div class="form-editor-container">
    <div class="d-flex justify-content-between align-items-end mb-2">
      <div class="d-flex">
        <router-link
          :to="{ name: 'custom-forms' }"
          v-slot="{ href, route, navigate }"
          custom
        >
          <a :href="href" @click="navigate" class="mr-2"
            ><i class="fa fa-chevron-left mr-1"></i>Back</a
          >
        </router-link>
        <h2>{{ pageHeader }}</h2>
      </div>
      <div class="d-flex align-items-center">
        <b-form-input
          v-model="formName"
          placeholder="Name your new form"
          size="sm"
          style="width: 300px; margin-right: 0.5rem"
        ></b-form-input>
        <b-button variant="success" size="sm" @click="validateForm"
          ><i class="fa fa-save mr-1"></i>Save</b-button
        >
      </div>
    </div>
    <div class="form-container">
      <!-- <div class="form-header">
          <h1>Custom Form Editor</h1>
      </div> -->
      <!-- <div v-if="formID != null && formData.sections != undefined">
          <form-builder type="template" :form="formData"></form-builder>
      </div>
      <div v-else> -->
      <!-- <form-builder type="template" v-model="formData"></form-builder> -->
      <div id="form-editor" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import jQuery from "jquery";
import formBuilder from "formBuilder";

let $ = (jQuery = require("jquery"));
$ = $.extend(require("webpack-jquery-ui"));
$ = $.extend(require("webpack-jquery-ui/css"));
$ = $.extend(require("formBuilder/dist/form-render.min.js"));

let utils = require("@/assets/global/js/utils.js");

export default {
  name: "CustomFormBuilder",
  components: {},
  data() {
    return {
      formsList: [],
      formID: null,
      formName: null,
      formData: null,
      formIndex: null,
      pageHeader: "",
      form: null
    };
  },
  mounted() {
    let $this = this;
    let is_jQuery = $ === jQuery;

    if (is_jQuery) {
      var options = {
        disabledActionButtons: ["data", "save"]
      };
      this.form = $("#form-editor").formBuilder(options);
    }

    var form_id = this.$route.params["form_id"];
    if (form_id != undefined) {
      $this.formID = form_id;
    }
    this.initView();
  },
  watch: {},
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  methods: {
    initView: function () {
      console.log("Viewing Create New Form - CustomFormEditor");
      var $this = this;
      // $this.$parent.pageTitle = "Create Custom Forms";
      // console.log("Create Custom Forms", $this.formID);

      if ($this.formID) {
        var API_URL =
          $this.getAPIServerURL +
          "/api/visitorservice/form/?form_id=" +
          $this.formID;
        const client = $this.$root.getAjaxFetchClient();
        client
          .getRequest(API_URL)
          .then((data) => {
            for (let url in data.result) {
              let data2 = data.result[url];
              $this.pageHeader = "Update Form - " + data2.name;
              $this.formData = data2.form_data;
              $this.formName = data2.name;
              $this.formIndex = data2.index;

              $this.$nextTick(() => {
                $this.form.actions.setData($this.formData);
              });
            }
          })
          .catch((err) => {
            console.log("Create New Form", err);
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
            // if (err.status == 401) {
            //   $this.$store.dispatch("session/logoutSession");
            // }
          });
      }
    },
    validateForm: function (e) {
      e.preventDefault();
      var $this = this;

      // Check for empty name field
      if (this.formName == null || this.formName == "") {
        this.$bvModal.msgBoxOk("Please enter a name for your form", {
          centered: true
        });
        return;
      }

      this.formData = $this.form.actions.getData("json", true);

      // SUCCESS - Set post data
      var API_URL = $this.getAPIServerURL + "/api/visitorservice/form/";
      if ($this.formID) {
        API_URL =
          $this.getAPIServerURL +
          "/api/visitorservice/form/?form_id=" +
          $this.formID;
      }
      const client = $this.$root.getAjaxFetchClient();
      var post_data = {
        name: this.formName,
        form_data: this.formData,
        form_type: "custom",
        enable: false
      };

      if ($this.formID) {
        console.log("UPDATE_FORM", this.formName);
        client
          .putRequest(API_URL, post_data)
          .then((data) => {
            if (data == null) {
              var update_data = {
                id: this.formID,
                name: this.formName,
                index: this.formIndex,
                form_data: this.formData
              };
              $this.formsList = update_data;
              // $this.$store.dispatch("psim/updateForm", update_data);
              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text:
                  'Updated Form "' + this.formName + '" successfully',
                message_type: "success"
              });
            }
          })
          .catch((err) => {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
            // if (err.status == 401) {
            //   $this.$store.dispatch("session/logoutSession");
            // }
          });
      } else {
        client
          .postRequest(API_URL, post_data)
          .then((data) => {
            if (data.result != undefined) {
              for (let url in data.result) {
                let form_id = data.result[url];
                var form_data = {
                  id: form_id,
                  ...post_data
                };
                // $this.$store.dispatch("psim/addForm", form_data);
                $this.formsList = form_data;
                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text:
                    'Added Form "' + this.formName + '" successfully',
                  message_type: "success"
                });
                $this.$router.push({ name: "custom-forms" }).catch(() => {});
              }
            }

            if (data.detail != undefined) {
              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: data.detail,
                message_type: "danger"
              });
            }
          })
          .catch((err) => {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: err.detail,
              message_type: "danger"
            });
            // if (err.status == 401) {
            //   $this.$store.dispatch("session/logoutSession");
            // }
          });
      }
    }
  }
};
</script>

<style lang="scss">
@import "CustomForm";
</style>
